<template>
    <v-app>
        <!-- Application Bar -->
        <v-app-bar>
            <v-app-bar-nav-icon @click="toggleDrawer" class="toggle-button" />

            <v-img :src="isDarkTheme ? '/logo-horizontal-white.png' : '/logo-horizontal-black.png'" 
                max-height="40"
                max-width="120"
                @click="$router.push('/')"
                style="cursor: pointer" />
            <span class="title-markazi ml-2">Intelligence Services</span>
            <v-spacer />

            <!-- Theme toggle -->
            <v-btn-toggle v-model="themeChoice" mandatory rounded="pill" density="comfortable" color="violean">
                <v-tooltip :text="$t('bar.lightTheme')">
                    <template v-slot:activator="{ props }">
                        <v-btn value="light" size="small" v-bind="props">
                            <v-icon>mdi-weather-sunny</v-icon>
                        </v-btn>
                    </template>
                </v-tooltip>

                <v-tooltip :text="$t('bar.autoTheme')">
                    <template v-slot:activator="{ props }">
                        <v-btn value="auto" size="small" v-bind="props">
                            <v-icon>mdi-theme-light-dark</v-icon>
                        </v-btn>
                    </template>
                </v-tooltip>

                <v-tooltip :text="$t('bar.darkTheme')">
                    <template v-slot:activator="{ props }">
                        <v-btn value="dark" size="small" v-bind="props">
                            <v-icon>mdi-weather-night</v-icon>
                        </v-btn>
                    </template>
                </v-tooltip>
            </v-btn-toggle>

            <!-- Locale toggle -->
            <v-btn-toggle v-model="localeChoice" mandatory rounded="pill" density="comfortable" color="violean"
                class="ml-2">
                <v-tooltip text="English">
                    <template v-slot:activator="{ props }">
                        <v-btn value="en" size="small" v-bind="props">
                            <span class="text-subtitle-2">🇺🇸</span>
                        </v-btn>
                    </template>
                </v-tooltip>

                <v-tooltip text="Français">
                    <template v-slot:activator="{ props }">
                        <v-btn value="fr" size="small" v-bind="props">
                            <span class="text-subtitle-2">🇫🇷</span>
                        </v-btn>
                    </template>
                </v-tooltip>
            </v-btn-toggle>
        </v-app-bar>

        <!-- Navigation Drawer -->
        <v-navigation-drawer v-model="drawer" :rail="rail" permanent>
            <v-list>
                <v-list-item :prepend-icon="rail ? 'mdi-chevron-right' : 'mdi-chevron-left'" title=""
                    @click.stop="rail = !rail" />
                <v-divider />
                <v-list-item to="/prompt" value="prompt" @click="handleItemClick">
                    <template v-slot:prepend>
                        <v-icon class="icon-class">mdi-message-text</v-icon>
                    </template>
                    <v-list-item-title class="menu-markazi">{{ $t('menu.prompt') }}</v-list-item-title>
                </v-list-item>
                <v-list-item to="/knowledge" value="knowledge" @click="handleItemClick">
                    <template v-slot:prepend>
                        <v-icon class="icon-class">mdi-brain</v-icon>
                    </template>
                    <v-list-item-title class="menu-markazi">{{ $t('menu.knowledge') }}</v-list-item-title>
                </v-list-item>
                <v-list-item to="/home" value="home" @click="handleItemClick">
                    <template v-slot:prepend>
                        <v-icon class="icon-class">mdi-home</v-icon>
                    </template>
                    <v-list-item-title class="menu-markazi">{{ $t('menu.dashboard') }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <!-- Main Content -->
        <v-main>
            <v-container fluid>
                <router-view />
            </v-container>
        </v-main>

        <!-- Global Alert -->
        <v-snackbar v-model="snackbar" :color="color" timeout="3000">
            {{ message }}
            <template v-slot:actions>
                <v-btn variant="text" @click="snackbar = false">Close</v-btn>
            </template>
        </v-snackbar>
    </v-app>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import { useTheme } from 'vuetify'
import { useI18n } from 'vue-i18n'
import { useSnackbarStore } from '@/stores/snackbarStore'
import { userStore } from '@/stores/userStore'
import { storeToRefs } from 'pinia'

// --- Drawer ---
const drawer = ref(true)
const rail = ref(false)

const toggleDrawer = () => {
    drawer.value = !drawer.value
}

// --- Theme ---
const theme = useTheme()
const themeChoice = ref(localStorage.getItem('themePreference') || 'auto')

// Watch for theme changes and save to localStorage
watch(themeChoice, (newValue) => {
    localStorage.setItem('themePreference', newValue)
    updateTheme(newValue)
})

// Function to update theme based on choice
const updateTheme = (choice: string) => {
    if (choice === 'auto') {
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches
        theme.global.name.value = prefersDark ? 'dark' : 'light'
    } else {
        theme.global.name.value = choice
    }
}

// Initialize theme on component mount
onMounted(() => {
    updateTheme(themeChoice.value)

    // Listen for system theme changes when in auto mode
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
        if (themeChoice.value === 'auto') {
            theme.global.name.value = e.matches ? 'dark' : 'light'
        }
    })
})

const isDarkTheme = computed(() => theme.global.current.value.dark)

const { locale } = useI18n()
const localeChoice = ref(localStorage.getItem('localePreference') || 'en')
const user = userStore()

watch(localeChoice, (newValue) => {
    localStorage.setItem('localePreference', newValue)
    locale.value = newValue
    // Update HTML lang attribute
    document.documentElement.lang = newValue
    // Store in user preferences
    user.setLang(newValue)
})

onMounted(() => {
    // Initialize locale from user store
    const storedLang = user.currentLang
    if (storedLang) {
        localeChoice.value = storedLang
        locale.value = storedLang
        document.documentElement.lang = storedLang
    }
})

// Get snackbar store
const snackbarStore = useSnackbarStore()
const { show: snackbar, message, color } = storeToRefs(snackbarStore)

const handleItemClick = (event: Event) => {
    // Prevent drawer from opening when in rail mode
    if (rail.value) {
        event.stopPropagation();
    }
};
</script>

<style scoped>
.toggle-button {
    position: relative;
    z-index: 1000;
}
</style>

<style>
.pt-sans-table {
    font-family: 'PT Sans', 'Lucida Sans Unicode' !important;
}

/* Target all cells including headers */
.pt-sans-table :deep(td),
.pt-sans-table :deep(th) {
    font-family: 'PT Sans', 'Lucida Sans Unicode' !important;
}

.title-markazi {
    font-family: 'Markazi Text', Georgia !important;
    align-items: center;
    display: flex;
    font-size: 30px;
    font-weight: semibold;
}

.sub-title-markazi {
    font-family: 'Markazi Text', Georgia !important;
    align-items: center;
    display: flex;
    font-size: 20px;
    font-weight: regular;
}

.menu-markazi {
    font-family: 'Markazi Text', Georgia !important;
    align-items: center;
    display: flex;
    font-size: 26px;
    font-weight: semibold;
}

.icon-class {
    font-weight: lighter;
}
</style>
