import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createVuetify } from 'vuetify'
import 'vuetify/styles'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import App from './App.vue'
import router from './router/index'
import { createI18n } from 'vue-i18n'
import '@mdi/font/css/materialdesignicons.css'
import { login, logout, silentLogin } from './services/entraID'

const app = createApp(App)
const vuetify = createVuetify({
    defaults: {
        VTooltip: {
            contentClass: 'pt-sans-table'
        },
        VSnackbar: {
            contentClass: 'pt-sans-table',
            align: 'center'
        },
        VTextarea: {
            contentClass: 'pt-sans-table'
        },
        VDialog: {
            contentClass: 'pt-sans-table'
        },
        VBtn: {
            contentClass: 'pt-sans-table'
        }
    },
    components,
    directives,
    theme: {
        defaultTheme: 'light',
        themes: {
            light: {
                colors: {
                    blackberry: '#14131B',
                    violean: '#8FAEF4',
                    elderberry: '#001D5E',
                    justgray: '#9D9D9F',
                    tangerine: '#F3B216',
                    cranberry: '#EA4E74',
                    blueberry: '#2864AE',
                    apple: '#7FB928'
                }
            },
            dark: {
                colors: {
                    blackberry: '#14131B',
                    violean: '#8FAEF4',
                    elderberry: '#001D5E',
                    justgray: '#9D9D9F',
                    tangerine: '#F3B216',
                    cranberry: '#EA4E74',
                    blueberry: '#2864AE',
                    apple: '#7FB928'
                }
            }
        }
    }
})

const i18n = createI18n({
    locale: localStorage.getItem('localePreference') || 'en',
    fallbackLocale: 'en',
    messages: {
        en: {
            welcome: {
                welcome: 'Welcome back {name} 👋',
                startJourney: 'Start your AI journey'
            },
            bar: {
                lightTheme: 'Light theme',
                autoTheme: 'Auto theme',
                darkTheme: 'Dark theme'
            },
            menu: {
                home: 'Home',
                dashboard: 'Dashboard',
                knowledge: 'Knowledge',
                prompt: 'Prompt'
            },
            dialog: {
                cancel: 'Cancel',
                save: 'Save',
                delete: 'Delete',
                deleteConfirm: 'Are you sure you want to delete this? This action is irreversible.',
                invalidUrl: 'Invalid URL',
                update: 'Update',
                close: 'Close',
                copyToClipboard: 'Copy to clipboard',
                copiedToClipboard: 'Copied to clipboard',
                copyToClipboardFailed: 'Failed to copy to clipboard'
            },
            table: {
                itemsPerPage: 'Items per page',
                itemsPerPageAll: 'All'
            },
            home: {
                welcome: 'Welcome back {name} 👋',
                subtitle: 'Here\'s what\'s happening with your application today.',
                recentActivity: 'Recent Activity',
                users: 'Users',
                actions: 'Actions',
                details: 'Details',
                status: 'Status',
                updatedAt: 'Updated at',
                search: 'Search',
                created: 'created'
            },
            prompt: {
                prompts: 'Prompts',
                users: 'Users',
                actions: 'Actions',
                details: 'Details',
                status: 'Status',
                updatedAt: 'Updated at',
                search: 'Search',
                title: 'Title',
                content: 'Content',
                provider: 'Provider',
                model: 'Model',
                temperature: 'Temperature',
                addPrompt: 'Create a Prompt',
                createPrompt: 'Create Prompt',
                selectRag: 'Select a knowledge to enable RAG',
                askWithoutSaving: 'Chat without saving',
                editPrompt: 'Edit Prompt',
                deletePrompt: 'Delete Prompt',
                processPrompt: 'Process Prompt',
                improvePrompt: 'Improve my prompt',
                currentPrompt: 'Current prompt',
                currentAnswer: 'Current answer',
                applyImprovement: 'Update the prompt',
                promptResponse: 'Prompt Response',
                strengths: 'Strengths',
                weaknesses: 'Weaknesses',
                improvements: 'Prompt analysis',
                generateResponse: 'Try my prompt',
                usesRag: 'This prompt uses RAG with type ({type})',
                chat: 'Chat',
                knowledge: 'Knowledge',
                validation: {
                    titleRequired: 'Title is required',
                    contentRequired: 'Content is required',
                    contentTooShort: 'Content must be at least 10 characters',
                    contentTooLong: 'Content cannot exceed 1000 characters',
                    providerRequired: 'Provider is required',
                    modelRequired: 'Model is required'
                }
            },
            knowledge: {
                title: 'Knowledge Base',
                addKnowledge: 'Add Knowledge',
                createNew: 'Create a new Knowledge element',
                editKnowledge: 'Edit Knowledge',
                fields: {
                    title: 'Title',
                    description: 'Description',
                    type: 'Type',
                    region: 'Region',
                    bucket: 'Bucket',
                    lastUpdate: 'Last Update',
                    status: 'Status',
                    processed: 'Processed',
                    pending: 'Pending',
                    lastProcessed: 'Last processed at {date}',
                    url: 'URL',
                    jiraProject: 'Jira Project',
                    file: 'File',
                    jiraCreatedAfter: 'Created after',
                    jiraCreatedAfterHint: 'Only fetch issues created after this date'
                },
                types: {
                    s3: 'AWS S3',
                    recursive_url: 'Recursive URL',
                    jira: 'Jira',
                    file_loader: 'File'
                },
                actions: {
                    cancel: 'Cancel',
                    save: 'Save',
                    update: 'Update',
                    process: 'Confirmation of processing knowledge',
                    processConfirm: 'Are you sure you want to process this knowledge? It may be time consuming and cost money.',
                    decline: 'I prefer not',
                    confirm: 'Yes, I want',
                    delete: 'Delete'
                },
                validation: {
                    requiredFields: 'Please fill in all required fields',
                    s3Fields: 'Region and bucket are required for S3 type',
                    urlRequired: 'URL is required for recursive URL type',
                    jiraProjectRequired: 'Jira project is required',
                    fileRequired: 'Please select a file',
                    titleRequired: 'Title is required',
                    descriptionRequired: 'Description is required',
                    typeRequired: 'Type is required',
                    urlInvalid: 'Please enter a valid URL',
                    regionRequired: 'Region is required',
                    bucketRequired: 'Bucket is required',
                    unsupportedFileType: 'Unsupported file type. Accepted file types: {types}'
                },
                success: {
                    created: 'Knowledge created successfully'
                },
                error: {
                    create: 'Error creating knowledge'
                }
            }
        },
        fr: {
            welcome: {
                welcome: 'Bienvenue {name} 👋',
                startJourney: "Commencer votre voyage sur l'IA"
            },
            bar: {
                lightTheme: 'Thème clair',
                autoTheme: 'Thème automatique',
                darkTheme: 'Thème sombre'
            },
            menu: {
                home: 'Accueil',
                dashboard: 'Tableau de bord',
                knowledge: 'Connaissances',
                prompt: 'Chat'
            },
            dialog: {
                cancel: 'Annuler',
                save: 'Enregistrer',
                delete: 'Supprimer',
                deleteConfirm: 'Êtes-vous sûr de vouloir supprimer cela ? Cette action est irréversible.',
                invalidUrl: 'URL invalide',
                update: 'Mettre à jour',
                close: 'Fermer',
                copyToClipboard: 'Copier dans le presse-papiers',
                copiedToClipboard: 'Copié dans le presse-papiers',
                copyToClipboardFailed: 'Erreur lors de la copie'
            },
            table: {
                itemsPerPage: 'Éléments par page',
                itemsPerPageAll: 'Tous'
            },
            home: {
                welcome: 'Bienvenue {name} 👋',
                subtitle: 'Voici ce qui se passe sur votre application aujourd\'hui.',
                recentActivity: 'Activité récente',
                users: 'Utilisateurs',
                actions: 'Actions',
                details: 'Détails',
                status: 'Statut',
                updatedAt: 'Mis à jour le',
                search: 'Rechercher',
                created: 'créés'
            },
            prompt: {
                prompts: 'Chats',
                users: 'Utilisateurs',
                actions: 'Actions',
                details: 'Détails',
                status: 'Statut',
                updatedAt: 'Mis à jour le',
                search: 'Rechercher',
                title: 'Titre',
                content: 'Contenu',
                provider: 'Fournisseur',
                model: 'Modèle',
                temperature: 'Température',
                addPrompt: 'Ajouter un chat',
                createPrompt: 'Créer un chat',
                selectRag: 'Sélectionner une connaissance pour activer le RAG',
                askWithoutSaving: 'Chatter seulement',
                editPrompt: 'Modifier le chat',
                deletePrompt: 'Supprimer le chat',
                processPrompt: 'Lancer le chat',
                improvePrompt: 'Améliorer ce chat',
                currentPrompt: 'Chat actuel',
                currentAnswer: 'Réponse actuelle',
                applyImprovement: 'Mettre à jour le chat',
                promptResponse: 'Votre chat',
                strengths: 'Points forts',
                weaknesses: 'Points faibles',
                improvements: 'Analyse du prompt',
                generateResponse: 'Essayer mon prompt',
                usesRag: 'Ce chat est basé sur RAG de type ({type})',
                chat: 'Chat',
                knowledge: 'Connaissance',
                validation: {
                    titleRequired: 'Le titre est requis',
                    contentRequired: 'Le contenu est requis',
                    contentTooShort: 'Le contenu doit contenir au moins 10 caractères',
                    contentTooLong: 'Le contenu ne peut pas dépasser 1000 caractères',
                    providerRequired: 'Le fournisseur est requis',
                    modelRequired: 'Le modèle est requis'
                }
            },
            knowledge: {
                title: 'Base de connaissances',
                addKnowledge: 'Ajouter une connaissance',
                createNew: 'Créer un nouvel élément de connaissance',
                editKnowledge: 'Modifier la connaissance',
                fields: {
                    title: 'Titre',
                    description: 'Description',
                    type: 'Type',
                    region: 'Région',
                    bucket: 'Bucket',
                    lastUpdate: 'Dernière mise à jour',
                    status: 'Statut',
                    processed: 'Traité',
                    pending: 'En attente',
                    lastProcessed: 'Dernier traitement le {date}',
                    url: 'URL',
                    jiraProject: 'Projet Jira',
                    file: 'Fichier',
                    jiraCreatedAfter: 'Créé après',
                    jiraCreatedAfterHint: 'Ne récupérer que les tickets créés après cette date'
                },
                types: {
                    s3: 'AWS S3',
                    recursive_url: 'URL Récursive',
                    jira: 'Jira',
                    file_loader: 'Fichier'
                },
                actions: {
                    cancel: 'Annuler',
                    save: 'Enregistrer',
                    update: 'Mettre à jour',
                    process: 'Confirmation du traitement',
                    processConfirm: 'Êtes-vous sûr de vouloir traiter cette connaissance ? Cela peut prendre du temps et coûter de l\'argent.',
                    decline: 'Je préfère pas',
                    confirm: 'Oui, je veux',
                    delete: 'Supprimer'
                },
                validation: {
                    requiredFields: 'Veuillez remplir tous les champs obligatoires',
                    s3Fields: 'La région et le bucket sont requis pour le type S3',
                    urlRequired: 'L\'URL est requise pour le type URL récursif',
                    jiraProjectRequired: 'Le projet Jira est requis',
                    fileRequired: 'Veuillez sélectionner un fichier',
                    titleRequired: 'Le titre est requis',
                    descriptionRequired: 'La description est requise',
                    typeRequired: 'Le type est requis',
                    urlInvalid: 'Veuillez entrer une URL valide',
                    regionRequired: 'La région est requise',
                    bucketRequired: 'Le bucket est requis',
                    unsupportedFileType: 'Type de fichier non pris en charge. Types de fichiers acceptés : {types}'
                },
                success: {
                    created: 'Connaissance créée avec succès'
                },
                error: {
                    create: 'Erreur lors de la création de la connaissance'
                }
            }
        }
    }
})

// Initialize the app
const initApp = async () => {

    app.use(createPinia())
    app.use(router)
    app.use(vuetify)
    app.use(i18n)

    app.mount('#app')
}

initApp().catch(console.error);

app.provide('login', login)
app.provide('logout', logout)
app.provide('silentLogin', silentLogin)

silentLogin();
