import { defineStore } from 'pinia'

interface UserState {
  firstname: string | null
  lastname: string | null
  email: string | null
  idToken: string | null
  accessToken: string | null
  itemsPerPage: number
  theme: 'light' | 'dark'
  lang: string
}

const STORAGE_KEY = 'user-state'

export const userStore = defineStore('user', {
  state: (): UserState => {
    const savedState = localStorage.getItem(STORAGE_KEY)
    if (savedState) {
      return JSON.parse(savedState)
    }
    return {
      firstname: null,
      lastname: null,
      email: null,
      idToken: null,
      accessToken: null,
      itemsPerPage: 10,
      theme: 'light',
      lang: 'en'
    }
  },
  getters: {
    currentTheme: (state) => state.theme,
    currentLang: (state) => state.lang,
    isDarkTheme: (state) => state.theme === 'dark'
  },
  actions: {
    setInfo(firstname: string, lastname: string, email: string, idToken: string, accessToken: string) {
      this.firstname = firstname
      this.lastname = lastname
      this.email = email
      this.idToken = idToken
      this.accessToken = accessToken
      this.saveState()
    },
    setItemsPerPage(items: number) {
      this.itemsPerPage = items
      this.saveState()
    },
    setTheme(theme: 'light' | 'dark') {
      this.theme = theme
      this.saveState()
    },
    setLang(lang: string) {
      this.lang = lang
      this.saveState()
    },
    saveState() {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(this.$state))
    }
  }
})
